html, body {
  background-color: white;
}

.App {
  max-width: 1440px;
  margin: 0 auto;
  text-align: left;
}

.main-content-container {
  background-color: #fcfcfc;
  background-size: 100% auto;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.10);
  box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.10);
}

.logo-image {
  width: 64px;
  height: 64px;
  margin-left: 64px;
  margin-top: 64px;
}

@media print, screen and (max-width: 767px) {
  .logo-image {
    width: 48px;
    height: 48px;
    margin-left: 24px;
    margin-top: 24px;
  }
}

.main-content-section {
  padding-top: 546px;
}

@media (max-width: 1280px) {
  .main-content-section {
    padding-top: 488px;
  }
}

@media (max-width: 1024px) {
  .main-content-section {
    padding-top: 326px;
  }
}

@media (max-width: 768px) {
  .main-content-section {
    padding-top: 326px;
  }
}

@media (max-width: 620px) {
  .main-content-section {
    padding-top: 276px;
  }
}

@media (max-width: 540px) {
  .main-content-section {
    padding-top: 196px;
  }
}

@media (max-width: 480px) {
  .main-content-section {
    padding-top: 170px;
  }
}

@media (max-width: 420px) {
  .main-content-section {
    padding-top: 132px;
  }
}

html, body, .App {
  font-family: sofia-pro, 'Helvetica Neue', Arial, Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  color: #212121; /* #0d0d0d; */
  font-size: 16px;
}

.main-section-backround-wrapper {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top 936px left 0px;
}

@media (min-width: 1281px) {
  .main-section-backround-wrapper {
    padding-bottom: 72px;
    background-position: top 964px left 0px;
  }
}

@media (max-width: 690px) {
  .main-section-backround-wrapper {
    background-image: none !important;
  }
}

.grid-x {
  max-width: 768px; /* 960px; 1280px */
  margin: 0 auto;
  padding: 0 24px;
}

@media (max-width: 1280px) {
  .grid-x {
    max-width: 768px; /* 960px; 1220px */
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .grid-x {
    max-width: 768px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .grid-x {
    max-width: 720px;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .grid-x {
    max-width: 540px;
    margin: 0 auto;
  }
}

/*
xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1280px

  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1220px
*/

a, img {
  border: none;
}

b, strong, h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: sofia-pro, 'Helvetica Neue', Arial, Roboto, sans-serif;
  line-height: 1.1;
  margin: 32px 0 8px 0;
}

h1 {
  font-size: 36px;
  letter-spacing: -0.041em;
  margin: 0px 0 16px 0;
}

@media print, screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
}

@media print, screen and (max-width: 480px) {
  h1 {
    font-size: 28px;
  }
}

h2 {
  font-size: 24px;
  letter-spacing: -0.027em;
  margin: 0px 0 16px 0;
}

h3 {
  font-size: 20px;
  margin: 8px 0 16px 0;
}

p {
  line-height: 1.42;
}

p.lead {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
}

@media print, screen and (max-width: 480px) {
  p.lead {
    font-size: 16px;
    line-height: 1.4;
  }
}

button, a.cta {
  height: 48px;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 16px;
  font-weight: 400;
  background-color: #222;
  color: white;
  border-radius: 24px;
  text-align: center;
  line-height: 48px;
  text-decoration: none;
  display: inline-block;
  border: none;
}

button:hover {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  font-size: 12px;
  padding-bottom: 82px;
  text-align: center;
  padding-top: 50px;
}

footer p {
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 1.25;
}

footer .social {
  font-size: 24px;
  margin-bottom: 22px;
}

footer .social a {
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;
}

@media (max-width: 768px) {
  footer {
    padding-bottom: 120px;
  }
}

.round-card {
  border-radius: 32px;
  padding: 48px 48px 32px 48px;
  background-color: white;
  border: none;
  margin: 0 0 30px 0;
  line-height: 1.4445;
  -webkit-box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.10);
  box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.10);
}

@media print, screen and (max-width: 480px) {
  .round-card {
    padding: 32px 32px 16px 32px;
  }
}


.round-card.secondary {
  background: rgb(255,226,111);
  background: linear-gradient(120deg, rgba(255,226,111,1) 0%, rgba(254,208,26,1) 100%);
  -webkit-box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.14);
  -moz-box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.14);
  box-shadow: 0px 10px 39px 0px rgba(0,0,0,0.14);
}

@media print, screen and (min-width: 768px) {
  .round-card {
    height: 488px;
  }
}

@media print, screen and (min-width: 768px) {
  .round-card.main {
    height: auto;
  }
}

@media print, screen and (min-width: 768px) {
  .round-card.left {
    margin-right: 15px;
  }
}

@media print, screen and (min-width: 768px) {
  .round-card.right {
    margin-left: 15px;
  }
}

.round-card .cta {
  margin-bottom: 16px;
}

.pricing h3 {
  margin-bottom: 16px;
}

.company-info .round-card {
  /* color: white;
  background-color: #212121; */
}

.company-info .round-card a {
  display: inline;
}

.terms {
  padding: 64px 24px 120px 24px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.terms .cta {
  margin-bottom: 48px;
}

.cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #212121;
  background-color: #fff;
  font-size: 12px;
  z-index: 10000;
  border-top: 1px solid #f8f8f8;
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.08);
}

.cookie-notice button {
  height: 32px;
  line-height: 32px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 12px;
  margin-left: 16px;
  color: #212121;
  background-color: #f8f8f8;
}

@media (max-width: 768px) {
  .cookie-notice button {
    margin-left: 0;
    margin-right: 16px;
    margin-top: 8px;

  }
}

.cookie-notice-contents {
  margin: 8px auto;
}

@media (max-width: 768px) {
  .cookie-notice-contents {
    margin: 16px auto;
  }
}

.cookie-notice-text {
  display: inline-block;
}

@media (max-width: 768px) {
  .cookie-notice-text {
    display: block;
    width: 100%;
  }
}

.cta, button.cta, a.cta {
  color: #0d0d0d;
  background: rgb(255,226,111);
  background: linear-gradient(120deg, rgba(255,226,111,1) 0%, rgba(254,203,0,1) 100%);
}

.company-info a, .company-info p  {
  color: #413A1B;
  font-size: 12px;
  /* color: white;
  background-color: #181818; */
}

.company-info .social a, .company-info .social p {
  font-size: 20px;
}

.company-info a {
  color: #413A1B;
  text-decoration: none;
}

@media print, screen and (max-width: 767px) {
  .grid-x>.medium-6 {
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .grid-x>.medium-6 {
    
  }
}